<template>
  <div class="mini_game_bet">
    <div class="mini_game">
      <div class="m1 dmr">
        <div class="mini_fram">
          <leisure-game-ifram-view>
            <iframe id="skypark-gamefram" scrolling="no" frameborder="0"
                    src="https://spark-api000.com/pc/game/mini/dice.aspx"></iframe>
          </leisure-game-ifram-view>
        </div>
      </div>
      <div class="m2">
        <div class="game_tab">
          <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
          <leisure-timer-comp
              ref="ltimer"
              :leisure-game-info="leisureGameInfo"
              @timerStop="timerStop" @refreshTimer="refreshTimer">
            <span slot="title" class="text-orange">SKY 주사위 </span>
          </leisure-timer-comp>

          <!--선택부분-->
          <div class="mini_bet_box">

            <div class="mg">
              <span class="t">홀/짝</span>
              <div class="mg_btn b2"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SKY_DICE_ODDEVEN && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SKY_DICE_ODDEVEN,leisureGameInfo.leisureGame.id,1, oddevenConfig.odds1, oddevenConfig, '홀')">
                <div class="r rblue">
                  <span class="n">홀</span>
                  <span class="b">{{ oddevenConfig.odds1 }}</span>
                </div>
              </div>
              <div class="mg_btn b2"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SKY_DICE_ODDEVEN && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SKY_DICE_ODDEVEN,leisureGameInfo.leisureGame.id,2,  oddevenConfig.odds2, oddevenConfig, '짝')">
                <div class="r rred">
                  <span class="n">짝</span>
                  <span class="b">{{ oddevenConfig.odds2 }}</span>
                </div>
              </div>
            </div>

            <div class="mg">
              <span class="t">히든카드 오버/언더</span>
              <div class="mg_btn b2"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SKY_DICE_OVERUNDER&& selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SKY_DICE_OVERUNDER,leisureGameInfo.leisureGame.id,1, overunderConfig.odds1, overunderConfig, '오버')">
                <div class="r rblue">
                  <span class="n">오버</span>
                  <span class="b">{{ overunderConfig.odds1 }}</span>
                </div>
              </div>
              <div class="mg_btn b2"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SKY_DICE_OVERUNDER && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SKY_DICE_OVERUNDER,leisureGameInfo.leisureGame.id,2, overunderConfig.odds2, overunderConfig, '언더')">
                <div class="r rred">
                  <span class="n">언더</span>
                  <span class="b">{{ overunderConfig.odds2 }}</span>
                </div>
              </div>
            </div>

            <div class="mg">
              <span class="t">높은주사위 RED/BLUE </span>
              <div class="mg_btn b2"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SKY_DICE_WINCOLOR && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SKY_DICE_WINCOLOR,leisureGameInfo.leisureGame.id,1, colorConfig.odds1, colorConfig, 'RED')">
                <div class="r rred">
                  <span class="n">RED</span>
                  <span class="b">{{ colorConfig.odds1 }}</span>
                </div>
              </div>
              <div class="mg_btn b2"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SKY_DICE_WINCOLOR && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SKY_DICE_WINCOLOR,leisureGameInfo.leisureGame.id,2, colorConfig.odds2, colorConfig, 'BLUE')">
                <div class="r rblue">
                  <span class="n">BLUE</span>
                  <span class="b">{{ colorConfig.odds2 }}</span>
                </div>
              </div>
            </div>

          </div>

          <!--파워볼 베팅카트-->
          <leisure-bet-cart-comp
              ref="betCart" :kind="kind"
              :selected-info="selectedInfo">
            주사위
          </leisure-bet-cart-comp>

        </div>
      </div>
      <div class="m3">

        <!--최근 배팅내역-->
        <leisure-lately-bet-list-comp
            ref="latelyBetList"
            :kind="kind"></leisure-lately-bet-list-comp>

      </div>


    </div>
  </div>
</template>

<script>
import LeisureTimerComp from "@/views/afront/leisure/LeisureTimerComp";
import LeisureBetCartComp from "@/views/afront/leisure/LeisureBetCartComp";
import LeisureBetMasker from "@/views/afront/leisure/LeisureBetMasker";
import LeisureLatelyBetListComp from "@/views/afront/leisure/LeisureLatelyBetListComp";
import LeisureGameIframView from "@/views/afront/leisure/LeisureGameIframView";

import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "@/store/mutation-types";
import leisureConst from "@/common/leisureConst";
import {leisureGameMixin} from "@/common/mixin";
import {getLeisureGameAttributeConfig} from "@/network/leisureRequest";

export default {

  name: "SkyDIce",
  mixins: [leisureGameMixin],
  components: {
    LeisureGameIframView,
    LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp,
  },
  data() {
    return {
      kind: leisureConst.LEISURE_KIND_SKY_DICE,
      iframniballUrl: '',
    }
  },

  methods: {
    initLeisureGameArttributeConfig() {
      getLeisureGameAttributeConfig().then(res => {
        if (res.data.success) {
          this.attrConfig = res.data.data

          //배당설정
          this.oddevenConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_SKY_DICE_ODDEVEN)
          })

          this.overunderConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_SKY_DICE_OVERUNDER)
          })

          this.colorConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_SKY_DICE_WINCOLOR)
          })
        }
      })
    }
  },
  created() {
    this.initKindConfig(this.kind)
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP, leisureConst.LEISURE_COMP_SKY);
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID, this.kind);
  },
}
</script>

<style scoped>
@media screen and (max-width: 1024px) {
  .dmr {
    min-height: 360px !important;
  }
}
</style>